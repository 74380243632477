.full-width-height {
  /* min-height: 425px;
  min-width: 375px; */
  width: 100%;
  height: 100%;
}

.video-chat-right-col {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
